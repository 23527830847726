.navbar {
    background-color: red;

}



.navbar-container {
    display: flex;
    /* align-items: center;  */
    margin: 0 auto;
    position: fixed;
    right: 0;
    height: 100vh;

}


.navbar-menu {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    position: fixed;
    right: 0;
    background-color: #FFD700;
    height: 100vh;
}

.navbar-menu li {
    margin: 0 15px;
}

.navbar-menu a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
}

.navbar-menu Button:hover {
    text-decoration: none;
    background-color: white;
}