.login-card{
    width: 400px;
    margin-top: 40%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    background-color: #fff;
}
.leaflet-marker-icon {
    border-radius: 50%;
}