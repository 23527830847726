.container {
    display: flex;
    margin-top: 2%;
    margin-bottom: 2%;
    height: 90vh;
    width: 95vw;
    box-sizing: border-box;
    flex-direction: column;
}

.spacer {
    width: 100%;
    height: 40px;
}

.nav {
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.main-content {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 2px;
}

.map {
    width: 100%;
    flex: 1;
}

.icon-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cars-component {
    width: 100%;
}

@media (min-width: 768px) {
    .container {
        flex-direction: row;
    }

    .main-content {
        flex-direction: row;
    }

    .cars-component,
    .map,
    .icon-button-container {
        width: 22vw;
    }

    .icon-button-container {
        width: auto;
        height: auto;
    }
}

@media (max-width: 767px) {
    .main-content {
        flex-direction: column;
    }

    .icon-button-container {
        width: 100%;
        height: auto;
    }

    .cars-component {
        width: 100%;
    }
}

::-webkit-scrollbar {
    display: none;
}

.your-element::-webkit-scrollbar {
    display: none;
}

.taskPanel {
    width: 19%;
    padding: 5px;
    height: 76vh;
    overflow-y: scroll;

}

.taskCard {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 26px;
    margin-bottom: 16px;
    background-color: #f9f9f9;
    align-items: center;

}

.taskCard:nth-child(even) {
    background-color: #e9e9e9;

}

.taskCard p {
    display: inline-block;
    margin-right: 60px;
    margin-left: 10px;
}

.task-button {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.task-content-border div{
    border: 1px solid #ccc;
}


/*  */
.toast-custom {
    background-color: #4CAF50 !important; /* Green background */
    color: white !important; /* White text */
  }
  
  .toast-custom-error {
    background-color: #f44336 !important; /* Red background */
    color: white !important; /* White text */
  }
  