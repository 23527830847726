.card {
    position: relative;
    margin-left: 80%;
    margin-top: 15%;
    height: 100%;
    width: 350px;
}

.formLabel {
    display: flex;
    justify-content: center;
    color: white;
}