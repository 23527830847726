
.map-space {
    border: 1px solid black;
    height: calc(92vh - 15vh);
    width: 100%;
    // background-image: url("../../../../public/Assets/Screenshot.png");
    background-repeat: no-repeat;
    background-size: cover;

}
